import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Form, Formik, useField } from 'formik';
import { object, string } from 'yup';
import { useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createCharge } from '../../services/backend/billing';
import { createInvoice } from '../../services/backend/invoices';
import {
  fetchSurvey,
  updateSurveyStatus,
} from '../../services/backend/surveys';
import { formatDollars } from '../../util/currency';
import { getNestedErrorMessages } from 'util/forms';
import { Question, Survey, SurveyVariable } from '../../types/domainModels';
import { showErrorMessage } from '../../util/notifications';
import { SURVEY_STATUSES } from 'constants/surveyStatuses';
import { surveyQueries } from 'hooks/backend/surveys';
import { useCurrentOrganization } from '../../hooks/backend/organizations';
import { useSubmitValidation } from '../../hooks/forms';

import ButtonLoading from 'components/common/forms/ButtonLoading';
import Checkbox from 'components/common/forms/Checkbox';
import FormErrorsAlert from 'components/common/forms/FormErrorsAlert';
import FormGroupOld from '../common/forms/FormGroup';
import FormInput from '../common/forms/FormInput';
import Hyperlink from '../common/Hyperlink';
import Icon from 'components/common/Icon';
import IconBackground from '../common/icons/IconBackground';
import ReviewSurveySummary from '../surveyEdit/ReviewSurveySummary';
import SurveyStepStickyHeader from './SurveyStepStickyHeader';
import TabGroup, {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  TabWithAlert,
} from 'components/common/Tabs';

interface ReviewFormData {
  checkout: {
    email: string;
    name: string;
    paidFor: boolean;
    paymentMethod: 'creditCard' | 'invoice';
    phone: string;
    purchaseOrder: string;
  };
}

function requiredForCreditCard(
  paidFor: ReviewFormData['checkout']['paidFor'],
  paymentMethod: ReviewFormData['checkout']['paymentMethod'],
) {
  return !paidFor && paymentMethod === 'creditCard';
}

const ReviewSchema = object().shape({
  checkout: object().shape({
    email: string().when(['paidFor', 'paymentMethod'], {
      is: requiredForCreditCard,
      then: (schema) =>
        schema
          .email('Please provide a valid email.')
          .required('Please provide your email.'),
    }),
    name: string().when(['paidFor', 'paymentMethod'], {
      is: requiredForCreditCard,
      then: (schema) => schema.required('Please provide your name.'),
    }),
  }),
});

const ReviewStep = ({
  onHasError,
  onStepCompleted,
  questions,
  survey,
  surveyId,
  surveyVariables,
}: {
  onHasError(): void;
  onStepCompleted(): void;
  questions: Question[];
  survey: Survey;
  surveyId: number;
  surveyVariables: SurveyVariable[];
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const queryClient = useQueryClient();

  const organization = useCurrentOrganization();
  const canChoosePaymentMethod = !!organization?.useInvoice;

  const initialValues = {
    checkout: {
      email: '',
      name: '',
      paidFor: survey.paidFor,
      paymentMethod: canChoosePaymentMethod ? 'invoice' : 'creditCard',
      phone: '',
      purchaseOrder: '',
    },
  } satisfies ReviewFormData;

  const { isPending: isLaunching, mutate: payAndLaunch } = useMutation({
    mutationFn: async (formData: ReviewFormData) => {
      // We want to be extra careful here and re-fetch the survey from the backend to check
      // if it's been paid for or not (as opposed to relying on front-end state which may be outdated).
      const surveyRefetched = await fetchSurvey({
        surveyId: survey.id,
      });

      if (!surveyRefetched.paidFor) {
        if (formData.checkout.paymentMethod === 'creditCard') {
          const cardElement = elements?.getElement('card');
          if (!stripe || !cardElement) {
            throw new Error('Could not load Stripe to complete payment.');
          }

          const { error, token } = await stripe.createToken(cardElement);
          if (error) {
            throw new Error(error.message);
          }

          await createCharge({
            data: {
              amount: surveyRefetched.estimatedBalance,
              campaignId: surveyRefetched.id,
              cardEmail: formData.checkout.email,
              cardName: formData.checkout.name,
              cardPhone: formData.checkout.phone,
              stripeToken: token?.id || '',
              termsAccepted: true,
            },
          });
        } else {
          await createInvoice({
            data: {
              cost: surveyRefetched.estimatedBalance,
              purchaseOrder: formData.checkout.purchaseOrder,
              surveyId: surveyRefetched.id,
            },
          });
        }
      }

      await updateSurveyStatus({
        data: { statusId: SURVEY_STATUSES.LIVE.id },
        surveyId: surveyRefetched.id,
      });
    },
    onError: (err) => {
      queryClient.invalidateQueries(
        surveyQueries.survey({ surveyId: survey.id }),
      );

      showErrorMessage({ err });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        surveyQueries.survey({ surveyId: survey.id }),
      );
      onStepCompleted();
    },
  });

  // We invalidate the survey on mount because the cost of the survey might have changed since the
  // last time the survey was fetched. We want to ensure we have the most up-to-date value.
  useEffect(() => {
    queryClient.invalidateQueries(surveyQueries.survey({ surveyId }));
  }, [surveyId, queryClient]);

  return (
    <Formik<ReviewFormData>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(formData) => {
        payAndLaunch(formData);
      }}
      validateOnChange={false}
      validationSchema={ReviewSchema}
    >
      <Form className="h-full">
        <ReviewForm
          canChoosePaymentMethod={canChoosePaymentMethod}
          isLoading={isLaunching}
          onHasError={onHasError}
          questions={questions}
          survey={survey}
          surveyVariables={surveyVariables}
        />
      </Form>
    </Formik>
  );
};

export default ReviewStep;

const ReviewForm = ({
  canChoosePaymentMethod,
  isLoading,
  onHasError,
  questions,
  survey,
  surveyVariables,
}: {
  canChoosePaymentMethod: boolean;
  isLoading: boolean;
  onHasError(): void;
  questions: Question[];
  survey: Survey;
  surveyVariables: SurveyVariable[];
}) => {
  const [{ value: paymentMethod }, , paymentMethodHelpers] = useField<
    ReviewFormData['checkout']['paymentMethod']
  >('checkout.paymentMethod');

  const { errors, onClickSubmit } = useSubmitValidation<ReviewFormData>({
    isSaving: isLoading,
    onHasError,
  });

  const submitText =
    survey.paidFor || survey.isBringYourOwnAudience ? 'Launch' : 'Pay & Launch';

  const nestedErrors = errors ? getNestedErrorMessages(errors) : [];

  const tabs = survey.isBringYourOwnAudience
    ? []
    : [
        <Tab key={0}>Summary</Tab>,
        <TabWithAlert key={1} hasAlert={!!errors?.checkout}>
          Checkout
        </TabWithAlert>,
      ];

  return (
    <div>
      <TabGroup>
        <SurveyStepStickyHeader>
          {tabs.length > 0 ? (
            <div className="grow -mb-2">
              <TabList size="sm">{tabs}</TabList>
            </div>
          ) : (
            <h2 className="text-base font-semibold">Summary</h2>
          )}

          <ButtonLoading
            disabled={nestedErrors.length > 0}
            hierarchy="primary"
            isLoading={isLoading}
            onClick={onClickSubmit}
            size="sm"
            // This can't currently be a submit button since we handle the form submission
            // in the onClickSubmit callback. If this is a "submit" button, it causes a double submission.
            type="button"
          >
            {submitText}
          </ButtonLoading>
        </SurveyStepStickyHeader>

        {nestedErrors.length > 0 && (
          <div className="mb-8">
            <FormErrorsAlert actionWord="launching" errors={nestedErrors} />
          </div>
        )}

        <TabPanels>
          <TabPanel>
            <ReviewSurveySummary
              questions={questions}
              survey={survey}
              surveyVariables={surveyVariables}
            />
          </TabPanel>
          {!survey.isBringYourOwnAudience && (
            <TabPanel>
              {survey.paidFor ? (
                <div className="flex items-center mt-4 space-x-2 text-sm">
                  <IconBackground title="Paid For">
                    <div className="w-4 h-4 text-primary-d-600">
                      <Icon id="check" />
                    </div>
                  </IconBackground>
                  <span>This survey has been paid for.</span>
                </div>
              ) : (
                <div className="space-y-6">
                  {canChoosePaymentMethod && (
                    <div className="flex space-x-6">
                      <Checkbox
                        checked={paymentMethod === 'invoice'}
                        label="Pay via Invoice"
                        name="checkout.paymentMethod"
                        onChange={(event) => {
                          if (event.currentTarget.checked) {
                            paymentMethodHelpers.setValue('invoice');
                          }
                        }}
                        radio={true}
                      />
                      <Checkbox
                        checked={paymentMethod === 'creditCard'}
                        label="Pay via Credit Card"
                        name="checkout.paymentMethod"
                        onChange={(event) => {
                          if (event.currentTarget.checked) {
                            paymentMethodHelpers.setValue('creditCard');
                          }
                        }}
                        radio={true}
                      />
                    </div>
                  )}

                  <div className="space-y-4">
                    {paymentMethod === 'invoice' ? (
                      <InvoiceForm cost={survey.estimatedBalance} />
                    ) : (
                      <CreditCardForm cost={survey.estimatedBalance} />
                    )}

                    <p className="text-dark-grey text-xs italic">
                      By launching this survey you agree to Glass's{' '}
                      <Hyperlink href="https://www.useglass.com/terms">
                        terms of service
                      </Hyperlink>
                      .
                    </p>
                  </div>
                </div>
              )}
            </TabPanel>
          )}
        </TabPanels>
      </TabGroup>
    </div>
  );
};

const CreditCardForm = ({ cost }: { cost: string }): JSX.Element => {
  return (
    <div className="space-y-4">
      <p className="text-sm">
        Your credit card will be charged{' '}
        <strong className="font-bold">{formatDollars(Number(cost))}</strong>.
      </p>
      <FormInput
        id="name"
        label="Name"
        labelFor="name"
        name="checkout.name"
        size="md"
        type="text"
      />
      <FormInput
        id="email"
        label="Billing Email"
        labelFor="email"
        name="checkout.email"
        size="md"
        type="email"
      />
      <FormInput
        id="phone"
        label="Phone"
        labelFor="phone"
        name="checkout.phone"
        size="md"
        type="phone"
      />
      <FormGroupOld label="Card Info" labelFor="stripe-card-element">
        <div className="w-full rounded-lg border border-gray-d-300 shadow-sm py-2 px-3">
          <CardElement id="stripe-card-element" />
        </div>
      </FormGroupOld>
    </div>
  );
};

const InvoiceForm = ({ cost }: { cost: string }): JSX.Element => {
  return (
    <div className="space-y-4">
      <p className="text-sm">
        An invoice will be submitted for{' '}
        <strong className="font-bold">{formatDollars(Number(cost))}</strong>.
      </p>
      <FormInput
        id="purchaseOrder"
        label="Purchase Order"
        labelFor="purchaseOrder"
        name="checkout.purchaseOrder"
        placeholder="(Optional)"
        size="md"
        type="text"
      />
    </div>
  );
};
