import axios from 'axios';

import { getAPIUrl } from './baseAPI';
import { queryStringify } from 'util/api';
import {
  SurveyVariable,
  SurveyVariableQuotaType,
  SurveyVariableSegmentResults,
} from '../../types/domainModels';

export interface CreateVariableBody {
  quotas: {
    numberNeeded: number | undefined;
    tempId: string;
    type: SurveyVariableQuotaType;
  }[];
  segments: {
    id?: number;
    isUserSpecified?: boolean;
    userIds?: string[];
    questions: {
      constraints: (
        | {
            conceptId: number;
          }
        | {
            optionId: number;
          }
        | {
            matrixOptions: string[];
            optionId: number;
          }
        | {
            matrixOptionIds: number[];
            optionId: number;
          }
        | {
            numberRange: { start: number; end: number };
            optionId: number;
          }
        | {
            numberRange: { start: number; end: number };
          }
        | {
            dateRange: { start: Date | null; end: Date | null };
          }
      )[];
      logicalModifier: 'is' | 'isnt' | 'should';
      questionId: number;
    }[];
    sort: number;
    tempQuotaId: string | undefined;
    title: string;
  }[];
  title: string;
}

export const CREATE_VARIABLE = ({
  surveyId,
}: {
  surveyId: number | string;
}) => ({
  path: `/surveys/${surveyId}/variables`,
  version: 'v1' as const,
});

export async function createVariable({
  data,
  surveyId,
}: {
  data: CreateVariableBody;
  surveyId: number | string;
}) {
  data.segments.forEach((segment, index) => {
    if (segment.isUserSpecified) {
      segment.questions = [];
    }
    segment.sort = index + 1;
  });

  return (
    await axios.post<SurveyVariable>(
      getAPIUrl(CREATE_VARIABLE({ surveyId })),
      data,
    )
  ).data;
}

export const DELETE_VARIABLE = ({
  surveyId,
  variableId,
}: {
  surveyId: number;
  variableId: number;
}) => ({
  path: `/surveys/${surveyId}/variables/${variableId}`,
  version: 'v1' as const,
});

export async function deleteVariable({
  surveyId,
  variableId,
}: {
  surveyId: number;
  variableId: number;
}) {
  return (
    await axios.delete(getAPIUrl(DELETE_VARIABLE({ surveyId, variableId })))
  ).data;
}

export const FETCH_VARIABLES = ({
  surveyId,
}: {
  surveyId: number | string;
}) => ({
  path: `/surveys/${surveyId}/variables`,
  version: 'v1' as const,
});

export async function fetchVariables({
  surveyId,
}: {
  surveyId: number | string;
}) {
  return (
    await axios.get<SurveyVariable[]>(getAPIUrl(FETCH_VARIABLES({ surveyId })))
  ).data;
}

export const FETCH_VARIABLE_RESULTS = ({
  variableId,
}: {
  variableId: number;
}) => ({
  path: `/surveys/variables/${variableId}/results`,
  version: 'v1' as const,
});

export async function fetchVariableResults({
  variableId,
  waveIds,
}: {
  variableId: number;
  waveIds?: number[];
}) {
  return (
    await axios.get<SurveyVariableSegmentResults[]>(
      getAPIUrl(FETCH_VARIABLE_RESULTS({ variableId })),
      {
        params: { waveIds },
        // The default query param format for the waveIds array is waveIds[]=1&waveIds[]=2,
        // but the backend expects waveIds=1&waveIds=2.
        paramsSerializer: queryStringify,
      },
    )
  ).data;
}

export const UPDATE_VARIABLE = ({
  surveyId,
  variableId,
}: {
  surveyId: number | string;
  variableId: number;
}) => ({
  path: `/surveys/${surveyId}/variables/${variableId}`,
  version: 'v1' as const,
});

export async function updateVariable({
  data,
  surveyId,
  variableId,
}: {
  data: CreateVariableBody;
  surveyId: number | string;
  variableId: number;
}) {
  data.segments.forEach((segment, index) => {
    if (segment.isUserSpecified) {
      segment.questions = [];
    }
    segment.sort = index + 1;
  });

  return (
    await axios.patch<SurveyVariable>(
      getAPIUrl(UPDATE_VARIABLE({ surveyId, variableId })),
      data,
    )
  ).data;
}
