import {
  queryOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import {
  createQuestionQuotas,
  fetchQuestionQuotas,
  removeQuestionQuotas,
} from 'services/backend/questionQuotas';
import { ReactQueryFunctionCallbacks } from 'types/internal';
import { questionQueries } from './questions';
import { surveyQueries } from './surveys';

export const questionQuotaQueries = {
  all: (opts: { surveyId: number }) => [
    'surveys',
    opts.surveyId,
    'question-quotas',
  ],
  forSurvey: (opts: { surveyId: number; waveIds: number[] }) =>
    queryOptions({
      queryFn: () =>
        fetchQuestionQuotas({
          surveyId: opts.surveyId,
          waveIds: opts.waveIds,
        }),
      queryKey: [
        ...questionQuotaQueries.all({ surveyId: opts.surveyId }),
        opts.waveIds,
      ],
    }),
};

export function useCreateQuestionQuotas(
  opts?: ReactQueryFunctionCallbacks<
    typeof createQuestionQuotas,
    { surveyId: number }
  >,
) {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: createQuestionQuotas,
    onSuccess: (...args) => {
      const surveyId = args[1].surveyId;

      queryClient.invalidateQueries({
        queryKey: surveyQueries.results({ surveyId }),
      });
      queryClient.invalidateQueries({
        queryKey: questionQueries.forSurvey({ surveyId: args[1].surveyId })
          .queryKey,
      });
      queryClient.invalidateQueries({
        queryKey: questionQuotaQueries.all({ surveyId }),
      });

      opts?.onSuccess?.(...args);
    },
  });
}

export function useRemoveQuestionQuotas(
  opts?: ReactQueryFunctionCallbacks<typeof removeQuestionQuotas>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: removeQuestionQuotas,
    onSuccess: (...args) => {
      queryClient.invalidateQueries();

      opts?.onSuccess?.(...args);
    },
  });
}
