import { clsx } from 'clsx';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ReactNode, useState } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { Form, Formik, FormikErrors, useField } from 'formik';
import { orderBy } from 'lodash-es';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { createTeamMemberOption } from '../../util/team';
import { DATE_FORMATS, formatDate } from '../../util/dates';
import { getNumsFromEstimatedRange } from 'util/incidence';
import { getOrgStatus } from '../../util/users';
import { incidenceTypeQueries } from 'hooks/backend/incidenceTypes';
import { questionQueries } from 'hooks/backend/questions';
import { ReactSelectValue } from '../../types/forms';
import { showErrorMessage } from '../../util/notifications';
import {
  surveyQueries,
  useCreateSurveyFromTemplate,
  useSaveSurvey,
} from 'hooks/backend/surveys';
import { SurveyTemplate, TeamMember } from 'types/domainModels';
import { useHasRole } from '../../hooks/users';
import { useOrderedOrganizations } from '../../hooks/backend/organizations';
import { useOrderedTeamMembers } from '../../hooks/backend/team';

import Button from '../common/forms/Button';
import ButtonLoading from '../common/forms/ButtonLoading';
import DIYIllustration from '../common/illustrations/DIYIllustration';
import DocumentAddIcon from '../common/icons/DocumentAddIcon';
import ErrorDisplay from '../common/ErrorDisplay';
import FormFieldError from 'components/common/forms/FormFieldError';
import FormGroup from 'components/common/forms/FormGroupNew';
import FormInput from '../common/forms/FormInput';
import FormLabel from 'components/common/forms/FormLabel';
import FormSearchSelectInput from '../common/forms/FormSearchSelectInput';
import Icon from 'components/common/Icon';
import Input from 'components/common/forms/Input';
import Modal, { ModalHeader } from '../common/Modal';
import Select from 'components/common/forms/Select';
import TemplateLibIllustration from '../common/illustrations/TemplateLibIllustration';
import UserBubble from '../common/UserBubble';

interface SelectTemplateFormData {
  organization: ReactSelectValue<number> | null;
  owner: ReactSelectValue<TeamMember> | null;
  title: string;
}

type NewSurveyStep =
  | 'createFromScratch'
  | 'chooseCreationRoute'
  | 'chooseTemplate';

function validateSelectTemplateData(
  formData: SelectTemplateFormData,
  { isAdmin = false } = {},
): FormikErrors<SelectTemplateFormData> {
  const errors: FormikErrors<SelectTemplateFormData> = {};

  if (!formData.organization && isAdmin) {
    errors.organization = 'Please select an organization.';
  }

  if (!formData.owner && isAdmin) {
    errors.owner = 'Please select an owner.';
  }

  if (!formData.title) {
    errors.title = 'Please enter a title.';
  }

  return errors;
}

const NewSurveyModal = ({
  onCloseModal,
}: {
  onCloseModal(): void;
}): JSX.Element => {
  const [newSurveyStep, setNewSurveyStep] = useState<NewSurveyStep>(
    'chooseCreationRoute',
  );

  return (
    <Modal
      header={
        <ModalHeader onClickClose={onCloseModal}>
          {newSurveyStep === 'chooseCreationRoute'
            ? 'Create a New Survey'
            : newSurveyStep === 'createFromScratch'
              ? 'Create New Survey'
              : 'Choose Template'}
        </ModalHeader>
      }
      onCloseModal={onCloseModal}
      position="top"
      size="auto"
    >
      <div
        className={clsx('max-w-full', {
          'w-[720px]': newSurveyStep === 'chooseCreationRoute',
          'w-[1080px]': newSurveyStep !== 'chooseCreationRoute',
        })}
      >
        {newSurveyStep === 'chooseCreationRoute' && (
          <ChoosingCreationRoute
            onClickChooseTemplate={() => {
              setNewSurveyStep('chooseTemplate');
            }}
            onClickFromScratch={() => {
              setNewSurveyStep('createFromScratch');
            }}
          />
        )}
        {newSurveyStep === 'createFromScratch' && (
          <CreateFromScratch
            onClickBack={() => {
              setNewSurveyStep('chooseCreationRoute');
            }}
          />
        )}
        {newSurveyStep === 'chooseTemplate' && <ChoosingTemplate />}
      </div>
    </Modal>
  );
};

export default NewSurveyModal;

const ChoosingCreationRoute = ({
  onClickChooseTemplate,
  onClickFromScratch,
}: {
  onClickChooseTemplate(): void;
  onClickFromScratch(): void;
}) => {
  return (
    <div>
      <p className="text-dark-grey text-sm">
        This is where you can create a new survey. Choose one of the options
        below to get started.
      </p>
      <div className="grid grid-cols-2 gap-8 mt-4 mx-4">
        <CreationRouteOption
          buttonText="Start from Scratch"
          description="Ready to start from scratch? Dive right in to the survey builder to select your audience, write your questions, and get insights quickly."
          illustration={
            <div className="absolute bottom-0 left-1/2 w-3/4 transform -translate-x-1/2">
              <DIYIllustration />
            </div>
          }
          onChooseRoute={onClickFromScratch}
          title="Do It Yourself"
        />
        <CreationRouteOption
          buttonText="Start from My Template Library"
          description="Start a survey from your template library to get started even quicker. Collaborate with co-workers to create templates that work for your team."
          illustration={
            <div className="absolute bottom-0 left-1/2 w-1/2 transform -translate-x-1/2">
              <TemplateLibIllustration />
            </div>
          }
          onChooseRoute={onClickChooseTemplate}
          title="My Template Library"
        />
      </div>
    </div>
  );
};

const CreationRouteOption = ({
  buttonText,
  description,
  illustration,
  isComingSoon = false,
  onChooseRoute,
  title,
}: {
  buttonText: string;
  description: string;
  illustration: ReactNode;
  isComingSoon?: boolean;
  onChooseRoute?: () => void;
  title: string;
}): JSX.Element => {
  return (
    <div className="flex flex-col items-center space-y-4">
      <h1 className="font-semibold">{title}</h1>
      <div className="relative w-full h-56 rounded-xl bg-light-purple overflow-hidden">
        {illustration}
      </div>
      <p className="flex-grow text-dark-grey text-sm text-center">
        {description}
      </p>
      <div className="flex w-full">
        <Button
          disabled={isComingSoon}
          grow
          hierarchy={isComingSoon ? 'secondary-gray' : 'primary'}
          icon={<Icon id="arrow-circle-right" />}
          iconPlacement="trailing"
          onClick={onChooseRoute}
          size="md"
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

const CreateFromScratch = ({ onClickBack }: { onClickBack(): void }) => {
  const { isLoadingOrgs, organizations: organizationOptions } =
    useOrderedOrganizations();

  return (
    <div className="flex justify-between">
      <div className="w-3/5 mr-8">
        {isLoadingOrgs ? null : (
          <CreateSurveyForm
            onClickBack={onClickBack}
            organizationOptions={organizationOptions}
          />
        )}
      </div>
      <div className="w-2/5">
        <div className="flex items-center justify-center w-full h-full">
          <div className="relative w-72 h-72 rounded-full bg-light-purple text-dark-grey text-sm">
            <div className="absolute top-1/2 left-1/2 w-3/4 transform -translate-x-1/2 -translate-y-1/2">
              <DIYIllustration />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

type CreateSurveyFormData = {
  contactId: number | null;
  incidenceTypeId: number | null;
  organizationId: number | null;
  respondents: number | '';
  title: string;
};

const CreateSurveyFormDataSchema = ({ isAdmin }: { isAdmin: boolean }) =>
  z.object({
    contactId: isAdmin
      ? z.number({ message: 'Please select a survey contact.' })
      : z.literal(null),
    incidenceTypeId: z.number({
      message: 'Please select an estimated incidence.',
    }),
    organizationId: z.number({ message: 'Please select an organization.' }),
    respondents: z.coerce
      .number()
      .min(1, 'Please enter the number of respondents.'),
    title: z.string().min(1, 'Please enter a title.'),
  });
type CreateSurveyFormDataValidated = z.infer<
  ReturnType<typeof CreateSurveyFormDataSchema>
>;

const CreateSurveyForm = ({
  onClickBack,
  organizationOptions,
}: {
  onClickBack(): void;
  organizationOptions: ReactSelectValue<number>[];
}) => {
  const navigate = useNavigate();
  const isAdmin = useHasRole('admin');

  const { isPending: isSavingSurvey, mutateAsync: saveSurvey } = useSaveSurvey({
    onError: (err) => {
      showErrorMessage({ err });
    },
    onSuccess: (data) => {
      navigate(`/surveys/${data.id}/build/overview`);
    },
  });

  const { currentOrganizationId } = getOrgStatus();

  const initialSurveyOrg = organizationOptions.find(({ value }) => {
    return value === currentOrganizationId;
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm<CreateSurveyFormData, unknown, CreateSurveyFormDataValidated>({
    defaultValues: {
      contactId: null,
      incidenceTypeId: null,
      organizationId: initialSurveyOrg?.value ?? null,
      respondents: '',
      title: '',
    },
    resolver: zodResolver(CreateSurveyFormDataSchema({ isAdmin })),
  });

  const onSubmit: SubmitHandler<CreateSurveyFormDataValidated> = (data) => {
    return saveSurvey({
      data: {
        // TODO: Not sure what campaignTypeAbbv means...
        campaignTypeAbbv: 'ci' as const,
        incidenceTypeId: data.incidenceTypeId,
        // TODO: Locations is required or else BE error...
        locations: [],
        organizationId: data.organizationId ?? undefined,
        ownerId: data.contactId ?? undefined,
        participants: data.respondents,
        projectId: null,
        // TODO: statusId = 6 means "draft"...
        statusId: 6,
        title: data.title,
        // TODO: Not sure what whiteLabeled means...
        whiteLabeled: false,
      },
    });
  };

  const organizationId = watch('organizationId');

  const { isLoadingTeamMembers, orderedTeamMembers } = useOrderedTeamMembers({
    enabled: isAdmin,
    organizationId,
  });

  // We only want to display survey contact options to the admin users if they have already selected the
  // organization to which the survey will belong.
  const teamMembers =
    isAdmin && !!organizationId
      ? orderedTeamMembers.map((teamMember) => {
          return {
            label: `${teamMember.firstName} ${teamMember.lastName}`,
            value: teamMember.id,
          };
        })
      : [];

  const { data: incidenceTypes = [], isLoading: isLoadingIncidenceTypes } =
    useQuery(incidenceTypeQueries.list);

  const orderedIncidenceTypes = orderBy(
    incidenceTypes,
    ({ name }) => {
      // An incidence type name looks like "100-75%" so the resulting split here will be
      // an array of ['100', '75%']. This ordering should eventually be moved to the back-end
      // because this is brittle.
      const [, end] = getNumsFromEstimatedRange(name);

      return end;
    },
    ['desc'],
  );
  const incidenceTypeOptions = orderedIncidenceTypes.map((incidenceType) => {
    return { label: incidenceType.name, value: incidenceType.id };
  });

  return (
    <form className="h-full space-y-4" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <FormGroup>
          <FormLabel labelFor="title">Survey Title</FormLabel>
          <Input
            {...register('title')}
            id="title"
            placeholder=" ex: Package testing study"
            size="md"
            type="text"
          />
          <ErrorMessage
            errors={errors}
            name="title"
            render={({ message }) => <FormFieldError error={message} />}
          />
        </FormGroup>
      </div>
      <div>
        <FormGroup>
          <FormLabel labelFor="respondents">Number of Respondents</FormLabel>
          <Input
            {...register('respondents')}
            id="respondents"
            placeholder="1000"
            size="md"
            type="number"
          />
          <ErrorMessage
            errors={errors}
            name="respondents"
            render={({ message }) => <FormFieldError error={message} />}
          />
        </FormGroup>
      </div>
      <div>
        <FormGroup>
          <FormLabel labelFor="incidenceTypeId">Estimated Incidence</FormLabel>
          <Controller
            control={control}
            name="incidenceTypeId"
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  inputId="incidenceTypeId"
                  isDisabled={isLoadingIncidenceTypes || field.disabled}
                  isLoading={isLoadingIncidenceTypes}
                  onChange={(o) => {
                    field.onChange(o?.value ?? null);
                  }}
                  options={incidenceTypeOptions}
                  value={incidenceTypeOptions.find(
                    (o) => o.value === field.value,
                  )}
                />
              );
            }}
          />
          <ErrorMessage
            errors={errors}
            name="incidenceTypeId"
            render={({ message }) => <FormFieldError error={message} />}
          />
        </FormGroup>
      </div>
      {organizationOptions.length > 1 && (
        <FormGroup>
          <FormLabel labelFor="organization">Organization</FormLabel>
          <Controller
            control={control}
            name="organizationId"
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  inputId="organizationId"
                  onChange={(o) => {
                    // If an organization is changed, we load new options for the survey contact
                    // so we want to clear out the existing one and make the user select an
                    // updated value.
                    setValue('contactId', null);

                    field.onChange(o?.value ?? null);
                  }}
                  options={organizationOptions}
                  value={organizationOptions.find(
                    (o) => o.value === field.value,
                  )}
                />
              );
            }}
          />
          <ErrorMessage
            errors={errors}
            name="organizationId"
            render={({ message }) => <FormFieldError error={message} />}
          />
        </FormGroup>
      )}
      {isAdmin && (
        <FormGroup>
          <FormLabel labelFor="contact">Survey Contact</FormLabel>
          <Controller
            control={control}
            name="contactId"
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  inputId="contactId"
                  isDisabled={isLoadingTeamMembers || field.disabled}
                  isLoading={isLoadingTeamMembers}
                  onChange={(o) => {
                    field.onChange(o?.value ?? null);
                  }}
                  options={teamMembers}
                  value={teamMembers.find((o) => o.value === field.value)}
                />
              );
            }}
          />
          <ErrorMessage
            errors={errors}
            name="contactId"
            render={({ message }) => <FormFieldError error={message} />}
          />
        </FormGroup>
      )}
      <div className="flex flex-row-reverse gap-4">
        <ButtonLoading grow isLoading={isSavingSurvey} size="md" type="submit">
          Create Survey
        </ButtonLoading>
        <Button
          grow
          hierarchy="secondary-gray"
          icon={<Icon id="arrow-circle-left" />}
          iconPlacement="leading"
          onClick={onClickBack}
          size="md"
          type="button"
        >
          Back
        </Button>
      </div>
    </form>
  );
};

const ChoosingTemplate = () => {
  const [selectedTemplate, setSelectedTemplate] =
    useState<SurveyTemplate | null>(null);

  const {
    data: templates = [],
    error: loadTemplatesError,
    isError: hasLoadTemplatesError,
    isLoading: isLoadingTemplates,
  } = useQuery(surveyQueries.surveyTemplates);

  return (
    <div className="pb-4">
      <p className="text-dark-grey text-sm">
        Select a template from your library to get started.
      </p>
      <div className="flex justify-between mt-4">
        <div className="w-3/5 mr-8">
          <h1 className="mb-2 text-sm font-semibold">Template Library</h1>
          {hasLoadTemplatesError && (
            <ErrorDisplay
              message={`Failed to load template library. (${loadTemplatesError?.message})`}
            />
          )}
          {isLoadingTemplates ? (
            <SurveyTemplatePlaceholderRows />
          ) : (
            <div className="h-96 overflow-auto">
              {templates.length === 0 && (
                <div className="text-sm">
                  <span>You have no templates. Use the</span>{' '}
                  <div className="inline-block w-4 h-4">
                    <DocumentAddIcon />
                  </div>{' '}
                  <span>
                    icon in the quick actions of "Your Surveys" to create a
                    template from an existing survey.
                  </span>
                </div>
              )}
              {templates.map((template, i) => {
                const isSelected = selectedTemplate?.id === template.id;

                return (
                  <ExistingSurveyTemplate
                    key={template.id}
                    isLastRow={i === templates.length - 1}
                    isSelected={isSelected}
                    onClickTemplate={() => {
                      setSelectedTemplate(template);
                    }}
                    template={template}
                  />
                );
              })}
            </div>
          )}
        </div>
        <div className="w-2/5">
          <SelectedTemplateGetStarted selectedTemplate={selectedTemplate} />
        </div>
      </div>
    </div>
  );
};

const SurveyTemplatePlaceholderRows = (): JSX.Element => {
  const rows = [1, 2, 3, 4];

  return (
    <>
      {rows.map((rowNum) => {
        return (
          <div key={rowNum} className="py-4 px-2 border-b border-light-grey">
            <div className="flex space-x-2 animate-pulse">
              <div className="w-5 h-5 mt-1 border border-light-grey rounded-full bg-light-grey" />
              <div className="w-full space-y-2">
                <div className="w-3/4 h-4 bg-light-grey" />
                <div className="w-1/2 h-4 bg-light-grey" />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

const ExistingSurveyTemplate = ({
  isLastRow,
  isSelected,
  onClickTemplate,
  template,
}: {
  isLastRow: boolean;
  isSelected: boolean;
  onClickTemplate(): void;
  template: SurveyTemplate;
}): JSX.Element => {
  const { createdAt, description, title, user } = template;

  return (
    <div
      className={clsx('flex w-full py-4 px-2 space-x-2 cursor-pointer', {
        'bg-darker-white': isSelected,
        'hover:bg-dark-white': !isSelected,
        'border-b border-light-grey': !isLastRow,
      })}
      onClick={onClickTemplate}
    >
      <div
        className={clsx(
          'flex-shrink-0 flex items-center justify-center w-5 h-5 mt-1 border border-light-grey rounded-full',
          {
            'bg-gray-400': isSelected,
          },
        )}
      >
        {isSelected && (
          <div className="w-4 h-4 text-white">
            <Icon id="check" />
          </div>
        )}
      </div>
      <div className="flex-grow">
        <div className="flex justify-between mb-1">
          <div className="pr-2">
            <p>{title}</p>
            <p className="text-dark-grey text-xs">
              Created:{' '}
              {formatDate(createdAt, {
                format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
              })}
            </p>
          </div>
          <div className="flex-shrink-0">
            <UserBubble user={user} />
          </div>
        </div>
        <p className="text-dark-grey text-sm">{description}</p>
      </div>
    </div>
  );
};

const SelectedTemplateGetStarted = ({
  selectedTemplate,
}: {
  selectedTemplate: SurveyTemplate | null;
}): JSX.Element => {
  const navigate = useNavigate();

  const isAdmin = useHasRole('admin');

  const { currentOrganizationId, hasChangedOrg } = getOrgStatus();

  const { isLoadingOrgs, organizations: organizationOptions } =
    useOrderedOrganizations();
  const initialSurveyOrg = hasChangedOrg
    ? organizationOptions.find(({ value }) => {
        return value === currentOrganizationId;
      })
    : undefined;

  const { isPending: isCreatingSurvey, mutate: createSurveyFromTemplate } =
    useCreateSurveyFromTemplate({
      onError: (err) => {
        showErrorMessage({ err });
      },
      onSuccess: (survey) => {
        navigate(`/surveys/${survey.id}/build/overview`);
      },
    });

  return (
    // The -mt-4 is to pull this div up so the "Questions in Template" header aligns with
    // the "Template Library" header.
    <div className="flex flex-col h-full -mt-4">
      {selectedTemplate ? (
        <Formik<SelectTemplateFormData>
          enableReinitialize={true}
          initialValues={{
            organization: initialSurveyOrg ?? null,
            owner: null,
            title: '',
          }}
          onSubmit={(formData) => {
            return createSurveyFromTemplate({
              data: {
                organizationId: isAdmin
                  ? formData.organization?.value
                  : undefined,
                ownerId: isAdmin ? formData.owner?.value.id : undefined,
                title: formData.title,
              },
              surveyId: selectedTemplate.id,
            });
          }}
          validate={(formData) => {
            return validateSelectTemplateData(formData, { isAdmin });
          }}
          validateOnBlur={false}
          validateOnChange={false}
        >
          <div className="flex flex-col h-full">
            <div className="flex-grow">
              <TemplateQuestions selectedTemplate={selectedTemplate} />
            </div>
            <div className="mt-4">
              <SelectedTemplateForm
                hasChangedOrg={hasChangedOrg}
                isAdmin={isAdmin}
                isCreatingSurvey={isCreatingSurvey}
                isLoadingOrgs={isLoadingOrgs}
                organizations={organizationOptions}
              />
            </div>
          </div>
        </Formik>
      ) : (
        <div className="flex items-center justify-center w-full h-full">
          <div className="relative w-72 h-72 rounded-full bg-light-purple text-dark-grey text-sm">
            <div className="absolute bottom-0 left-1/2 w-1/2 transform -translate-x-1/2">
              <TemplateLibIllustration />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const TemplateQuestions = ({
  selectedTemplate,
}: {
  selectedTemplate: SurveyTemplate;
}) => {
  const {
    data: questions = [],
    error: loadQuestionsError,
    isError: hasLoadTemplatesError,
    isLoading: isLoadingTemplateQuestions,
  } = useQuery({
    ...questionQueries.forSurvey({ surveyId: selectedTemplate.id }),
    refetchOnWindowFocus: false,
  });

  return (
    <div className="h-96 p-4 rounded bg-darker-white overflow-auto">
      <h1 className="mb-2 text-sm font-semibold">Questions in Template</h1>
      {hasLoadTemplatesError && loadQuestionsError instanceof Error && (
        <ErrorDisplay
          message={`Failed to load questions for template. (${loadQuestionsError?.message})`}
        />
      )}
      {isLoadingTemplateQuestions ? (
        <TemplateQuestionsPlaceholderRows />
      ) : (
        <ol className="pl-4 list-decimal">
          {questions.map((question) => {
            return (
              <li key={question.id} className="mb-1 text-sm">
                {question.title}
              </li>
            );
          })}
        </ol>
      )}
    </div>
  );
};

const TemplateQuestionsPlaceholderRows = (): JSX.Element => {
  const rows = [1, 2, 3, 4];

  return (
    <>
      {rows.map((rowNum) => {
        return (
          <div key={rowNum} className="mb-2">
            <div className="flex items-center space-x-2 animate-pulse">
              <div className="w-5 h-5 rounded-full bg-light-grey" />
              <div className="w-3/4 h-4 bg-light-grey" />
            </div>
          </div>
        );
      })}
    </>
  );
};

const SelectedTemplateForm = ({
  hasChangedOrg,
  isAdmin,
  isCreatingSurvey,
  isLoadingOrgs,
  organizations,
}: {
  hasChangedOrg: boolean;
  isAdmin: boolean;
  isCreatingSurvey: boolean;
  isLoadingOrgs: boolean;
  organizations: ReactSelectValue<number>[];
}): JSX.Element => {
  const [{ value: organization }] =
    useField<SelectTemplateFormData['organization']>('organization');
  const [, , setOwnerHelpers] =
    useField<SelectTemplateFormData['owner']>('owner');

  const organizationId = organization?.value;

  const { isLoadingTeamMembers, orderedTeamMembers } = useOrderedTeamMembers({
    enabled: isAdmin,
    organizationId,
  });

  // We only want to display survey owner options to the admin users if they have already selected the
  // organization to which the survey will belong.
  const teamMembers =
    isAdmin && !!organizationId
      ? orderedTeamMembers.map((teamMember) => {
          return createTeamMemberOption(teamMember);
        })
      : [];

  return (
    <Form>
      <div className="space-y-4">
        {isAdmin && (
          <>
            <FormSearchSelectInput
              inputId="organization"
              isDisabled={hasChangedOrg || isLoadingOrgs}
              isLoading={isLoadingOrgs}
              label="Which organization does the survey belong to?"
              labelFor="organization"
              name="organization"
              onChange={() => {
                // If an organization is changed, we load new options for the survey owner so we want to clear out
                // the existing one and make the user select an updated value.
                setOwnerHelpers.setValue(null);
              }}
              options={organizations}
            />
            <FormSearchSelectInput
              inputId="owner"
              isDisabled={isLoadingTeamMembers}
              isLoading={isLoadingTeamMembers}
              label="Who is the owner of the survey?"
              labelFor="owner"
              name="owner"
              options={teamMembers}
            />
            <hr className="text-light-grey" />
          </>
        )}
        <div className="flex items-start w-full space-x-4">
          <div className="flex-grow">
            <FormInput
              id="title"
              label="New Survey Title"
              labelFor="title"
              name="title"
              size="lg"
              type="text"
            />
          </div>
          <div className="mt-5">
            <ButtonLoading
              hierarchy="primary"
              isLoading={isCreatingSurvey}
              size="md"
              type="submit"
            >
              Create Survey
            </ButtonLoading>
          </div>
        </div>
      </div>
    </Form>
  );
};
