import axios from 'axios';

import {
  DataUrl,
  GaborGrangerFormat,
  GaborGrangerObjective,
  Question,
  QuestionQuotaDisqualificationType,
  QuestionQuotaLogicalModifiers,
  QuestionResults,
  QuestionTemplate,
} from '../../types/domainModels';
import { getAPIUrl } from './baseAPI';

export interface Audience {
  categories: {
    audienceSlices: [
      {
        audienceSliceCategories: {
          audienceSliceCategoryAttributes: AudienceSliceCategoryAttribute[];
          conceptId: number | null;
          logicalModifier: string;
          questionId: number;
        }[];
        percentage: 1;
      },
    ];
    dependentSlices: true;
    isPublic: false;
  };
  sort: number;
  unionModifier: 'and' | 'or';
}

export type AudienceSliceCategoryAttribute =
  | number
  | { id: number }
  | { end: number; start: number }
  | { end: number; enumValue: number; start: number }
  | { enumStringMap: string; enumValue: number; id: number };

export interface CreateQuestionBody {
  concepts: {
    audience: Audience[];
    description: string;
    id?: number;
    isActive: boolean;
    media: DataUrl | { toSave: { url: string } } | null;
    preserved: boolean;
  }[];
  constraint:
    | {
        range: { start: number; end: number };
      }
    | {
        errorMessage: string;
        regex: string;
      }
    | null;
  contentTypeId: number;
  displayOptionDescription?: boolean;
  displayedConcept: number | null;
  description: string | null;
  features: {
    code: string;
    enumRegex?: string;
    enumValue?: number;
    matrixOptionId?: number;
    regex?: string;
  }[];
  gaborGrangerSettings?: {
    format: GaborGrangerFormat;
    formatCustomText: string | null;
    increment: number;
    max: number;
    min: number;
    objective: GaborGrangerObjective;
    unitDecimals: number;
  };
  isActive: boolean;
  label: string | null;
  matrixOptions?: {
    id: number | null;
    isActive: boolean;
    isAnchored: boolean;
    isExclusive: boolean;
    isFreeText: boolean;
    title: string;
    weight: number | null;
  }[];
  monadicBlockEnd?: number;
  monadicBlockSequences?: number;
  monadicBlockStart?: number;
  multipleChoiceLimit: null;
  multipleChoiceLowerLimit?: number | null;
  multipleChoiceUpperLimit?: number | null;
  optionBlockSize?: number | null;
  options: {
    anchored: boolean;
    dataUrl: DataUrl | { toSave: { url: string } } | null;
    exclusive: boolean;
    id: number | null;
    isActive: boolean;
    isFreeTextOption: boolean;
    isMatrixMultipleChoice: false;
    questionOptionAudiences: Audience[];
    preserved: boolean;
    rangeMax?: number;
    rangeMin?: number;
    rangeStep?: number;
    scaleHighLabel?: string;
    scaleLowLabel?: string;
    scaleMiddleLabel?: string;
    scaleTypeId?: number;
    scaleUnitId?: number;
    title: string;
    viewRequired?: boolean;
    weight: number | null;
  }[];
  questionAudiences?: Audience[];
  questionTypeId: number;
  quotas?: {
    disqualificationType: QuestionQuotaDisqualificationType;
    logicalModifier: QuestionQuotaLogicalModifiers;
    numberNeeded: number | null;
    options: { id: number | null; title: string }[];
    quotaId: number | null;
  }[];
  requiredSum?: number | null;
  surveyId: string;
  title: string;
}

interface CreateQuestionTemplateBody {
  ownerId?: number;
  templateDescription: string;
  templateTitle: string;
}

export const CREATE_QUESTION = () => ({
  path: '/questions',
  version: 'v1' as const,
});

export async function createQuestion({ data }: { data: CreateQuestionBody }) {
  return (await axios.post<Question>(getAPIUrl(CREATE_QUESTION()), data)).data;
}

export const CREATE_QUESTION_TEMPLATE = ({
  questionId,
}: {
  questionId: number;
}) => ({
  path: `/questions/${questionId}/templates`,
  version: 'v1' as const,
});

export async function createQuestionTemplate({
  data,
  questionId,
}: {
  data: CreateQuestionTemplateBody;
  questionId: number;
}) {
  return (
    await axios.post<void>(
      getAPIUrl(CREATE_QUESTION_TEMPLATE({ questionId })),
      data,
    )
  ).data;
}

export const DELETE_QUESTION = ({ questionId }: { questionId: number }) => ({
  path: `/questions/${questionId}`,
  version: 'v1' as const,
});

export async function deleteQuestion({ questionId }: { questionId: number }) {
  return (await axios.delete(getAPIUrl(DELETE_QUESTION({ questionId })))).data;
}

export const FETCH_QUESTION = ({ questionId }: { questionId: number }) => ({
  path: `/questions/${questionId}`,
  version: 'v2' as const,
});

export async function fetchQuestion({ questionId }: { questionId: number }) {
  return (await axios.get<Question>(getAPIUrl(FETCH_QUESTION({ questionId }))))
    .data;
}

export const FETCH_QUESTION_TEMPLATES = () => ({
  path: '/questions/templates',
  version: 'v1' as const,
});

export async function fetchQuestionTemplates() {
  return (
    await axios.get<QuestionTemplate[]>(getAPIUrl(FETCH_QUESTION_TEMPLATES()))
  ).data;
}

export const FETCH_QUESTIONS_FOR_SURVEY = ({
  surveyId,
}: {
  surveyId: number | string;
}) => ({
  path: `/surveys/${surveyId}/questions`,
  version: 'v2' as const,
});

/**
 * Note: The questions returned here do not include any demographic questions.
 * See fetchSegmentationQuestionsForSurvey to get a list of all questions,
 * including demographics.
 */
export async function fetchQuestionsForSurvey({
  surveyId,
}: {
  surveyId: number | string;
}) {
  return (
    await axios.get<Question[]>(
      getAPIUrl(FETCH_QUESTIONS_FOR_SURVEY({ surveyId })),
    )
  ).data;
}

export const GET_QUESTION_RESULTS = ({
  questionId,
}: {
  questionId: number;
}) => ({
  path: `/questions/${questionId}:results`,
  version: 'v2' as const,
});

export async function getQuestionResults({
  data,
  questionId,
}: {
  data: { waveIds: number[] };
  questionId: number;
}) {
  return (
    await axios.post<QuestionResults>(
      getAPIUrl(GET_QUESTION_RESULTS({ questionId })),
      data,
    )
  ).data;
}

export const REORDER_QUESTIONS = ({ surveyId }: { surveyId: number }) => ({
  path: `/questions/sort-questions/${surveyId}`,
  version: 'v1' as const,
});

export async function reorderQuestions({
  data,
  surveyId,
}: {
  data: {
    blockId: number | null;
    id: number;
    monadicId: number | null;
    sort: number;
  }[];
  surveyId: number;
}) {
  return (
    await axios.patch<void>(getAPIUrl(REORDER_QUESTIONS({ surveyId })), data)
  ).data;
}

export const TOGGLE_QUESTION_ACTIVATION = ({
  questionId,
}: {
  questionId: number;
}) => ({
  path: `/questions/activate/${questionId}`,
  version: 'v1' as const,
});

export async function toggleQuestionActivation({
  questionId,
}: {
  questionId: number;
}) {
  return (
    await axios.patch<Question>(
      getAPIUrl(TOGGLE_QUESTION_ACTIVATION({ questionId })),
    )
  ).data;
}

export const UPDATE_QUESTION = ({ questionId }: { questionId: number }) => ({
  path: `/questions/${questionId}`,
  version: 'v1' as const,
});

export async function updateQuestion({
  data,
  questionId,
}: {
  data: CreateQuestionBody;
  questionId: number;
}) {
  return (
    await axios.patch<Question>(getAPIUrl(UPDATE_QUESTION({ questionId })), {
      ...data,
      // TODO: Discuss with backend about removing this flag.
      dirty: { concepts: true, options: true },
    })
  ).data;
}
