import { clsx } from 'clsx';
import { ComponentProps } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { formatDollars } from 'util/currency';
import { getLastDashboardRoute } from 'util/routes';
import { getTestLinks } from 'util/surveys';
import { organizationQueries } from 'hooks/backend/organizations';
import { SURVEY_STATUSES } from 'constants/surveyStatuses';
import { surveyQueries } from 'hooks/backend/surveys';

import Badge from 'components/common/Badge';
import Button from 'components/common/forms/Button';
import Icon from 'components/common/Icon';
import LogoCollapsed from 'components/common/LogoCollapsed';
import SurveyStatusBadge from 'components/common/SurveyStatusBadge';

const SurveyHeader = ({ surveyId }: { surveyId: number }) => {
  const navigate = useNavigate();

  const { data: survey } = useQuery(surveyQueries.survey({ surveyId }));

  const { data: fetchOrganizationsResponse } = useQuery(
    organizationQueries.list,
  );
  const organizations = fetchOrganizationsResponse?.organizations ?? [];
  const surveyOrg = organizations.find(
    (org) => org.id === survey?.organizationId,
  );

  return (
    <header className="flex h-full items-center pr-6 space-x-6 bg-primary-d-700">
      <NavLink
        className="flex items-center h-14 justify-center w-[82px]"
        to={getLastDashboardRoute()}
      >
        <LogoCollapsed />
      </NavLink>

      <div className="w-survey-page-sidebar shrink-0">
        <h1 className="font-medium truncate text-white leading-none">
          {survey?.title}
        </h1>
        {surveyOrg && organizations.length > 1 ? (
          <span className="text-gray-d-200 text-xs">{surveyOrg.name}</span>
        ) : null}
      </div>

      <div className="h-full flex space-x-6 items-center text-white">
        <nav className="h-full">
          <ul className="flex items-center h-full space-x-6">
            <li className="h-full">
              <HeaderLink to={`/surveys/${surveyId}/build`}>
                Build Survey
              </HeaderLink>
            </li>
            <div className="w-4 h-4">
              <Icon id="chevron-right" />
            </div>
            <li className="h-full">
              <HeaderLink to={`/surveys/${surveyId}/analyze`}>
                Analyze Results
              </HeaderLink>
            </li>
          </ul>
        </nav>

        {survey &&
          !survey.isBringYourOwnAudience &&
          survey.estimatedBalance && (
            <Badge color="primary" size="sm">
              {formatDollars(parseInt(survey.estimatedBalance, 10))}
            </Badge>
          )}
      </div>

      {survey && (
        <div className="flex space-x-6 grow justify-end items-center">
          {survey.wave.completes > 0 &&
            survey.status.name === SURVEY_STATUSES.COMPLETED.name && (
              <Button
                hierarchy="secondary-gray"
                icon={<Icon id="wave" />}
                iconPlacement="leading"
                onClick={() => {
                  navigate(`/surveys/${surveyId}/build/waves`);
                }}
                size="sm"
              >
                Add wave
              </Button>
            )}

          <a
            className="flex gap-2 items-center text-white"
            href={getTestLinks({ survey }).testLink}
            rel="noopener noreferrer"
            target="_blank"
          >
            <div className="w-5 h-5">
              <Icon id="eye" />
            </div>
            <span>Preview</span>
          </a>
          <SurveyStatusBadge survey={survey} />
        </div>
      )}
    </header>
  );
};

export default SurveyHeader;

const HeaderLink = (props: ComponentProps<typeof NavLink>) => {
  return (
    <NavLink
      {...props}
      className={({ isActive }) =>
        clsx('flex items-center h-full cursor-pointer text-sm', {
          'font-semibold': isActive,
          'text-gray-300': !isActive,
        })
      }
      end={false}
    />
  );
};
